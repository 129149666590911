@import "../../scss/default/color_variable";
.cs-iconbox_3_list {
  position: relative;
  padding-left: 550px;
  min-height: 640px;
  >*:not(:last-child) {
    border-bottom: 1px solid #4D4D4D;
  }
  .cs-image_layer {
    position: absolute;
    left: 0;
    top: 25px;
    pointer-events: none;
    max-width: 416px;
    height: 600px;
    .cs-image_layer_in {
      height: 100%;
      width: 100%;
    }
    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
      position: relative;
      clip-path: inset(0 100% 0 0);
      animation: inactiveImageAnimation1 0.6s forwards ease;
    }
  }
  .cs-hover_tab.active {
    .cs-image_layer {
      img {
        animation: activeImageAnimation1 0.6s forwards ease;
      }
    }
    .cs-iconbox_icon {
      color: #fff;
      background-color: $accent;
      border-color: $accent;
    }
  }
  @media (max-width: 1199px) {
    padding-left: 460px;
  }
  @media (max-width: 991px) {
    padding-left: 0;
    padding-top: 650px;
    .cs-image_layer {
      max-width: 100%;
      width: 100%;
    }
  }
}
.cs-iconbox.cs-style3 {
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  justify-content: space-between;
  padding-bottom: 25px;
  padding-top: 25px;
  color: $secondary;
  .cs-iconbox_in {
    margin-right: 30px;
    max-width: 480px;
  }
  .cs-iconbox_icon {
    color: $ternary;
    border: 2px solid $ternary;
    height: 80px;
    width: 80px;
    border-radius: 50%;
    flex: none;
    transition: all 0.3s ease;
    &:hover {
      color: #fff;
      background-color: $accent;
      border-color: $accent;
    }
  }
  .cs-iconbox_title {
    font-size: 30px;
    margin-bottom: 15px;
  }
  .cs-iconbox_subtitle {
    line-height: 1.75em;
  }
  &:hover {
    .cs-iconbox_icon {
      color: #fff;
      background-color: $accent;
      border-color: $accent;
    }
  }
  @media (max-width:991px) {
    .cs-iconbox_title {
      font-size: 26px;
      margin-bottom: 10px;
    }
  }
  @media (max-width:575px) {
    flex-direction: column;
    align-items: flex-start;
    .cs-iconbox_icon {
      position: initial;
      transform: initial;
      margin-bottom: 20px;
      height: 60px;
      width: 60px;
      svg {
        max-height: 22px;
      }
    }
  }
}
@keyframes inactiveImageAnimation1 {
  0%{
    clip-path:inset(0 0 0 0);
  }
  100%{
    clip-path:inset(0 100% 0 0);
  }
}
@keyframes activeImageAnimation1 {
  0%{
    clip-path:inset(0 0 0 100%);
  }
  100%{
    clip-path:inset(0 0 0 0);
  }
}

/* Popup Overlay */
.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

/* Popup Content */
.popup-content {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

/* Popup Content Inner */
.popup-content-inner {
  text-align: center;
}

/* Popup Close Button */
.popup-close {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
  padding: 5px;
}